import { notification } from "antd";
import {
  USER_CONNECTED,
  commandsToShowMessage,
} from "constants/commandConstants";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import Utils from "utils";
import { message as showMessage } from "antd";

const protocol = "dmt-cloud";
const ENV_DEV = "wss://dwclouddev.vestek.com.tr/DWCloud/ws";
const ENV_TEST = "wss://dwcloudtest.vestek.com.tr/DWCloud/ws";
const ENV_PROD = "wss://displaywizard.app/DWCloud/ws";
const ENV_LOCAL = "ws://192.168.1.115:5197/DWCloud/ws";

const WEB_SOCKET_URL = ENV_PROD;

const WebSocketContext = createContext(null);

export function WebSocketProvider({ children }) {
  const email = useSelector((state) => state.user.email);
  console.log(email);
  // const token = localStorage.getItem("auth_token");
  const token = useSelector((state) => state.auth.token);
  const query = `${WEB_SOCKET_URL}?token=${token}&connectionid=${connectionId}`;
  const websocket = useRef(null);
  const [readyState, setReadyState] = useState(WebSocket.CLOSED);
  const [lastMessage, setLastMessage] = useState(null);

  const initializeWebSocket = useCallback(() => {
    if (!token || !email) return;

    const ws = new WebSocket(query, protocol);
    let interval;

    ws.onopen = () => {
      console.log("open");
      setReadyState(WebSocket.OPEN);
      interval = setInterval(() => {
        ws.send(JSON.stringify(Utils.configureMessage("HEARTBEAT")));
      }, 50_000);
    };

    ws.onclose = (event) => {
      setReadyState(WebSocket.CLOSED);
      console.log("WebSocket closed with code:", event.code);
      clearInterval(interval);
      // if (![1000, 1005].includes(event.code) && token) {
      //   setTimeout(() => {
      //     initializeWebSocket();
      //   }, 5000);
      // }
    };

    ws.onmessage = (message) => {
      console.log(JSON.parse(message.data), "new ws message");
      const messageObj = JSON.parse(message.data);
      if (messageObj?.Body?.Result === USER_CONNECTED) {
        return;
      }
      if (
        messageObj?.DeviceIds?.length === 1 &&
        messageObj?.Body?.Type === 1 &&
        willDisplayed(messageObj?.Body?.Command)
      ) {
        showMessage.info(messageObj?.Body?.Result.replace("#*", ""));
      }
      setLastMessage(messageObj);
    };
    ws.onerror = (error) => {
      console.error("WebSocket Error: ", error);
    };

    websocket.current = ws;
  }, [query]);

  useEffect(() => {
    if (!token) return;

    initializeWebSocket();

    return () => {
      console.log("cleanup", websocket.current);
      websocket.current?.close();
      websocket.current = null;
    };
  }, [query, token, initializeWebSocket]);

  const sendMessage = (message) => {
    if (websocket.current && readyState !== WebSocket.OPEN) {
      notification.error({
        message: "Connection Error",
        description: "Cannot estabish connection with server",
      });
      return;
    }
    websocket.current.send(JSON.stringify(message));
  };

  const value = {
    websocket,
    lastMessage,
    sendMessage,
    readyState,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
}

export const connectionId = crypto.randomUUID();

/**
 * @returns {{ lastMessage: any, sendMessage: () => void, websocket: WebSocket, readyState: any }}
 */
function useWebSocket() {
  return useContext(WebSocketContext);
}

export default useWebSocket;

/**
 * @param { string } command
 * @returns { boolean }
 */
function willDisplayed(command) {
  return commandsToShowMessage.some((cmd) => command.includes(cmd));
}

// function decryptMessage(message) {
//   // Check if the message object is valid and has necessary properties
//   if (!message || typeof message !== "object") {
//     console.error("Invalid message format");
//     return null;
//   }

//   const { DeviceIds, Body } = message;
//   if (!Body || typeof Body !== "object") {
//     console.error("Message body is missing or invalid");
//     return null;
//   }

//   // Extracting command and result, providing default values if not present
//   const { Command = "", Result = "" } = Body;

//   return {
//     effectedDevices: Array.isArray(DeviceIds) ? DeviceIds : [],
//     command: Command,
//     response: Result,
//     is(command) {
//       return this.command === command;
//     },
//     isEmpty() {
//       // Check if the message object is actually empty
//       return !Command && !Result && (!DeviceIds || DeviceIds.length === 0);
//     },
//     isResponse() {
//       return !!this.response;
//     },
//   };
// }
