import { createSlice } from "@reduxjs/toolkit";
import { THEME_CONFIG } from "configs/AppConfig";

export const initialState = THEME_CONFIG;

export const themeSlice = createSlice({
  name: "theme",
  initialState,
  reducers: {
    toggleCollapsedNav: (state, action) => {
      localStorage.setItem("side_nav_collapse", action.payload);
      state.navCollapsed = action.payload;
    },
    onNavStyleChange: (state, action) => {
      localStorage.setItem("side_nav_theme", action.payload);
      state.sideNavTheme = action.payload;
    },
    onLocaleChange: (state, action) => {
      localStorage.setItem("lang", action.payload);
      state.locale = action.payload;
    },
    onNavTypeChange: (state, action) => {
      state.navType = action.payload;
    },
    onTopNavColorChange: (state, action) => {
      state.topNavColor = action.payload;
    },
    onHeaderNavColorChange: (state, action) => {
      state.headerNavColor = action.payload;
    },
    onMobileNavToggle: (state, action) => {
      state.mobileNav = action.payload;
    },
    onSwitchTheme: (state, action) => {
      localStorage.setItem("theme", action.payload);
      state.currentTheme = action.payload;
    },
    onDirectionChange: (state, action) => {
      state.direction = action.payload;
    },
    onBlankLayout: (state, action) => {
      state.blankLayout = action.payload;
    },
  },
});

export const {
  toggleCollapsedNav,
  onNavStyleChange,
  onLocaleChange,
  onNavTypeChange,
  onTopNavColorChange,
  onHeaderNavColorChange,
  onMobileNavToggle,
  onSwitchTheme,
  onDirectionChange,
  onBlankLayout,
} = themeSlice.actions;

export default themeSlice.reducer;
