export const REPLACE_TV_INFO =
  "*********************TV INFO*********************";
export const REPLATE_TV_MODE = "#*Display mode is ";
export const REPLACE_AUTO_LAUNCH = "#*Auto Launch Mode: ";
export const USER_CONNECTED = "User is connected.";
export const REPLACE_TIME_ZONE = "#*Timezone is ";
export const REPLACE_VOLUME = "#*volume level is ";
export const REPLACE_ORIENTATION = "#*The OSD orientation is ";
export const REPLACE_BRIGHTNESS = "#*THE BRIGHTNESS VALUE : ";
export const REPLACE_TIME = "#*Time = ";
export const GET_VOLUME = "GETVOLUME";
export const GET_BRIGHTNESS = "GETBRIGHTNESS";
export const GET_TV_MODE = "GETMODE";
export const SET_TV_MODE = (mode) => `SETMODE ${mode}`;
export const GET_ORIENTATION = "GETOSDORIENTATION";
export const GET_TIME_ZONE = "GTZ";
export const GET_TV_INFO = "GETTVINFO";
export const GET_TIME = "TIME";
export const GET_MAC_WIFI = "GETMACADDRESS wifi";
export const GET_MAC_BT = "GETMACADDRESS bt";
export const GET_MAC_ETH = "GETMACADDRESS eth0";
export const SET_AUTO_LAUNCH = "SETAUTOLAUNCH";
export const FIRMWARE_UPDATE = "OTAUPDATE";
export const NOT_CONNECTED = "Device is not connected.";
export const SET_SCHEDULE = "SETSCHEDULEOP";
export const SET_BACKLIGHT_SCHEDULE = "SCHEDULERBACKLIGHT";
export const GET_AUTO_LAUNCH = "GETAUTOLAUNCH";
// export const SET_VOLUME = "STV";
export const getSchedule = (number) => `GETSCHEDULEOP ${number}`;
export const GET_STANDBY = "GETSTANDBY";
export const TAKE_SCREENSHOT = "SCREENSHOT";
export const START_LIVE = (value) => `SCREENSHOT startLivestream ${value}`;
export const STOP_LIVE = `SCREENSHOT stopLivestream`;
export const GET_SCHEDULE = "GETSCHEDULEOP";

export const SET_VOLUME = (value) => `STV ${value}`;
export const SET_BRIGHTNESS = (value) => `SETBRIGHTNESS ${value}`;
export const SET_ORIENTATION = (value) => `SETOSDORIENTATION ${value}`;

export const SET_COMMANDS = [
  "STV",
  "SETBRIGHTNESS",
  "SETOSDORIENTATION",
  "SETSCHEDULEOP",
  "ENTERACTIVESTANDBY",
  "EXITACTIVESTANDBY",
  "RST",
  "RESET",
];

// POWER ACTIONS
export const TURN_OFF = "ENTERACTIVESTANDBY";
export const TURN_ON = "EXITACTIVESTANDBY";
// export const TURN_OFF = "TOF";
// export const TURN_ON = "TON";
export const RESTART = "RST";
export const RESET = "RESET";
export const INSTALL_APK = (url) => `UPGRADEAPK ${url}`;

export const acceptLanguage = {
  tr: "tr-TR",
  en: "en-US",
};

export const commandsToShowMessage = [
  TURN_OFF,
  TURN_ON,
  RESTART,
  RESET,
  SET_SCHEDULE,
  FIRMWARE_UPDATE,
  SET_AUTO_LAUNCH,
  "UPGRADEAPK",
  "STV",
  "SETBRIGHTNESS",
  "SETMODE",
  "SETOSDORIENTATION",
];
