import React, { useEffect } from "react";
import { onBlankLayout } from "store/slices/themeSlice";
import { useDispatch } from "react-redux";
import { WebSocketProvider } from "utils/hooks/useWebSocket";

const AppRoute = ({
  component: Component,
  routeKey,
  blankLayout,
  isUsingWebSocket,
  ...props
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const isBlank = blankLayout ? true : false;
    dispatch(onBlankLayout(isBlank));
  }, [blankLayout]);

  if (isUsingWebSocket) {
    return (
      <WebSocketProvider>
        <Component {...props} />
      </WebSocketProvider>
    );
  }

  return <Component {...props} />;
};

export default AppRoute;
