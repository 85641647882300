import React from "react";
import { AUTH_PREFIX_PATH, APP_PREFIX_PATH } from "configs/AppConfig";

export const publicRoutes = [
  {
    key: "login",
    path: `${AUTH_PREFIX_PATH}/login`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login")
    ),
  },
  {
    key: "login-1",
    path: `${AUTH_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
  },
  {
    key: "login-2",
    path: `${AUTH_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
  },
  {
    key: "register-1",
    path: `${AUTH_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
  },
  {
    key: "register-2",
    path: `${AUTH_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
  },
  {
    key: "forgot-password",
    path: `${AUTH_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
  },
  {
    key: "error-page-1",
    path: `${AUTH_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
  },
  {
    key: "error-page-2",
    path: `${AUTH_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
  },
];

export const protectedRoutes = [
  {
    key: "dashboard.default",
    path: `${APP_PREFIX_PATH}/dashboards/default`,
    component: React.lazy(() => import("views/app-views/dashboards/default")),
  },
  // {
  //   key: "dashboard.analytic",
  //   path: `${APP_PREFIX_PATH}/dashboards/analytic`,
  //   component: React.lazy(() => import("views/app-views/dashboards/analytic")),
  // },
  // {
  //   key: "dashboard.sales",
  //   path: `${APP_PREFIX_PATH}/dashboards/sales`,
  //   component: React.lazy(() => import("views/app-views/dashboards/sales")),
  // },
  {
    key: "devices",
    path: `${APP_PREFIX_PATH}/device/*`,
    component: React.lazy(() => import("views/app-views/devices/all")),
  },
  {
    key: "devices.device",
    path: `${APP_PREFIX_PATH}/device/:id`,
    component: React.lazy(() => import("views/app-views/devices/device")),
  },
  {
    key: "devices.group.management",
    path: `${APP_PREFIX_PATH}/device/group-management/*`,
    component: React.lazy(() =>
      import("views/app-views/devices/group-management")
    ),
  },
  {
    key: "devices.groups",
    path: `${APP_PREFIX_PATH}/device/groups/*`,
    component: React.lazy(() => import("views/app-views/devices/groups")),
  },
  {
    key: "devices.logs",
    path: `${APP_PREFIX_PATH}/device/logs/*`,
    component: React.lazy(() => import("views/app-views/devices/logs")),
  },
  // {
  //   key: "devices.bulkAdd",
  //   path: `${APP_PREFIX_PATH}/device/bulk-add/*`,
  //   component: React.lazy(() => import("views/app-views/devices/bulk-add")),
  // },
  {
    key: "devices.groups.group",
    path: `${APP_PREFIX_PATH}/device/groups/:id`,
    component: React.lazy(() => import("views/app-views/devices/group")),
  },
  {
    key: "administration.users",
    path: `${APP_PREFIX_PATH}/administration/users/*`,
    component: React.lazy(() => import("views/app-views/administration/users")),
  },
  {
    key: "administration.application.management",
    path: `${APP_PREFIX_PATH}/administration/application-management/*`,
    component: React.lazy(() =>
      import("views/app-views/administration/application")
    ),
  },
  {
    key: "administration.notifications",
    path: `${APP_PREFIX_PATH}/administration/notifications/*`,
    component: React.lazy(() =>
      import("views/app-views/administration/notifications")
    ),
  },

  {
    key: "login-1",
    path: `${APP_PREFIX_PATH}/login-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-1")
    ),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "login-2",
    path: `${APP_PREFIX_PATH}/login-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/login-2")
    ),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "register-1",
    path: `${APP_PREFIX_PATH}/register-1`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-1")
    ),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "register-2",
    path: `${APP_PREFIX_PATH}/register-2`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/register-2")
    ),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "forgot-password",
    path: `${APP_PREFIX_PATH}/forgot-password`,
    component: React.lazy(() =>
      import("views/auth-views/authentication/forgot-password")
    ),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "error-page-1",
    path: `${APP_PREFIX_PATH}/error-page-1`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-1")),
    meta: {
      blankLayout: true,
    },
  },
  {
    key: "error-page-2",
    path: `${APP_PREFIX_PATH}/error-page-2`,
    component: React.lazy(() => import("views/auth-views/errors/error-page-2")),
    meta: {
      blankLayout: true,
    },
  },
];
