import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { Provider } from "react-redux";
import { HashRouter } from "react-router-dom";
import { THEME_CONFIG } from "./configs/AppConfig";
import history from "./history";
import "./lang";
import Layouts from "./layouts";
import store from "./store";
import { WebSocketProvider } from "utils/hooks/useWebSocket";

const queryClient = new QueryClient();

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <HashRouter history={history}>
          {/* <WebSocketProvider> */}
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Layouts />
          </ThemeSwitcherProvider>
          {/* </WebSocketProvider> */}
        </HashRouter>
      </QueryClientProvider>
    </Provider>
  );
}

export default App;
