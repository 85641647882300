import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { AUTH_TOKEN } from "constants/AuthConstant";
import FirebaseService from "services/FirebaseService";
import { fillUserData } from "./userSlice";
// import store from "store";
import { LOGIN_URL } from "constants/ApiConstant";
import { acceptLanguage } from "constants/commandConstants";
import store from "../../store";

const token = localStorage.getItem(AUTH_TOKEN) || null;

if (token) {
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

const language = localStorage.getItem("lang") || "tr";
axios.defaults.headers.common["Accept-Language"] = acceptLanguage[language];

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      localStorage.removeItem("auth_token");
      axios.defaults.headers.common["Authorization"] = "";
      store.dispatch(signOut());
    }
    return Promise.reject(error);
  }
);

export const initialState = {
  loading: false,
  message: "",
  showMessage: false,
  redirect: "",
  token,
};

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export const signIn = createAsyncThunk(
  "auth/signIn",
  async (data, { rejectWithValue }) => {
    let errorMessage;
    data.projectName = "DW Cloud";
    try {
      const request = await axios
        .post(LOGIN_URL, data, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch((err) => {
          errorMessage = err.response.data.error;
        });

      if (errorMessage) {
        return rejectWithValue(errorMessage);
      }

      if (request.data) {
        const response = request.data;
        const userData = parseJwt(response.data.token);
        console.log(response.data.token);

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${response.data.token}`;

        const language = localStorage.getItem("lang") || "tr";
        axios.defaults.headers.common["Accept-Language"] =
          acceptLanguage[language];

        let projects =
          typeof userData.Project === "string"
            ? [userData.Project]
            : userData.Project;

        if (projects.includes("DW Cloud")) {
          const token = response.data.token;
          // localStorage.setItem(AUTH_TOKEN, token);
          localStorage.setItem(AUTH_TOKEN, token);
          store.dispatch(fillUserData(userData));
          return token;
        } else {
          return rejectWithValue("Rejected");
        }
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signUp = createAsyncThunk(
  "auth/signUp",
  async (data, { rejectWithValue }) => {
    const { email, password } = data;
    try {
      const response = await FirebaseService.signUpEmailRequest(
        email,
        password
      );
      if (response.user) {
        const token = response.user.refreshToken;
        localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
        return token;
      } else {
        return rejectWithValue(response.message?.replace("Firebase: ", ""));
      }
    } catch (err) {
      return rejectWithValue(err.message || "Error");
    }
  }
);

export const signOut = createAsyncThunk("auth/signOut", async () => {
  const response = await FirebaseService.signOutRequest();
  localStorage.removeItem(AUTH_TOKEN);
  return response.data;
});

export const signInWithGoogle = createAsyncThunk(
  "auth/signInWithGoogle",
  async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInGoogleRequest();
    if (response.user) {
      const token = response.user.refreshToken;
      localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
      return token;
    } else {
      return rejectWithValue(response.message?.replace("Firebase: ", ""));
    }
  }
);

export const signInWithFacebook = createAsyncThunk(
  "auth/signInWithFacebook",
  async (_, { rejectWithValue }) => {
    const response = await FirebaseService.signInFacebookRequest();
    if (response.user) {
      const token = response.user.refreshToken;
      localStorage.setItem(AUTH_TOKEN, response.user.refreshToken);
      return token;
    } else {
      return rejectWithValue(response.message?.replace("Firebase: ", ""));
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    authenticated: (state, action) => {
      state.loading = false;
      state.redirect = "/";
      state.token = action.payload;
    },
    showAuthMessage: (state, action) => {
      state.message = action.payload;
      state.showMessage = true;
      state.loading = false;
    },
    hideAuthMessage: (state) => {
      state.message = "";
      state.showMessage = false;
    },
    signOutSuccess: (state) => {
      console.log("dispatchdeyim");
      state.loading = false;
      state.token = null;
      state.redirect = "/";
    },
    showLoading: (state) => {
      state.loading = true;
    },
    signInSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload;
    },
    redirectToLogin: (state) => {
      state.redirect = "/auth/login";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signIn.pending, (state) => {
        state.loading = true;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signIn.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signOut.fulfilled, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signOut.rejected, (state) => {
        state.loading = false;
        state.token = null;
        state.redirect = "/";
      })
      .addCase(signUp.pending, (state) => {
        state.loading = true;
      })
      .addCase(signUp.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithGoogle.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithGoogle.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithGoogle.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      })
      .addCase(signInWithFacebook.pending, (state) => {
        state.loading = true;
      })
      .addCase(signInWithFacebook.fulfilled, (state, action) => {
        state.loading = false;
        state.redirect = "/";
        state.token = action.payload;
      })
      .addCase(signInWithFacebook.rejected, (state, action) => {
        state.message = action.payload;
        state.showMessage = true;
        state.loading = false;
      });
  },
});

export const {
  authenticated,
  showAuthMessage,
  hideAuthMessage,
  signOutSuccess,
  redirectToLogin,
  showLoading,
  signInSuccess,
} = authSlice.actions;

export default authSlice.reducer;
