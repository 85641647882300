const ENV_DEV = "https://dwclouddev.vestek.com.tr/api";
const ENV_TEST = "https://dwcloudtest.vestek.com.tr/api";
const ENV_PROD = "https://displaywizard.app/api";
const ENV_LOCAL = "http://192.168.1.115:5197/api";

const ENV_B2B_DEV = "https://b2bdev.vestek.com.tr/api";
const ENV_B2B_TEST = "https://b2blicencetest.vestek.com.tr/api";
const ENV_B2B_PROD = "https://cloudlicencemanager.com/api";
const ENV_B2B_LOCAL = "http://192.168.9.106:5162/api";

export const API_BASE_URL = ENV_PROD;
export const URl_DEVICE = `${API_BASE_URL}/device`;
export const URl_NOTIFICATIONS = `${API_BASE_URL}/NotificationCard`;
export const URl_COMPANY = `${API_BASE_URL}/company`;
export const URL_DASHBOARD = `${API_BASE_URL}/Dashboard`;
export const URL_GROUP = `${API_BASE_URL}/Group`;
export const URL_LOG = `${API_BASE_URL}/Log`;
export const URL_SCREENSHOOT = `${URL_LOG}/screenshots`;

export const URL_B2B = ENV_B2B_PROD; //"https://b2blicencetest.vestek.com.tr/api";
export const LOGIN_URL = `${URL_B2B}/auth/login`;

export const URL_USER = `${URL_B2B}/user`;
export const URL_USER_INFORMATION = `${URL_USER}/EditUserInformation`;
export const URL_USER_PROFILE = `${URL_USER}/EditUserProfile`;
export const URL_LICENCE = `${URL_B2B}/licence/2`;
export const CHANGE_PASSWORD_URL = `${URL_USER}/changepassword`;

export const URL_ACTIVATE_DEVICE = `${URl_DEVICE}/ActivateDevice`;
export const URL_DEACTIVATE_DEVICE = `${URl_DEVICE}/InactiveDevice`;

export const URL_APPLICATIONS = `${API_BASE_URL}/Application`;
